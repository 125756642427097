import React, { Fragment } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { Formik, Form, Field } from "formik"
import Modal from "shared/components/Modal"
import styled from "styled-components"
import Select from "shared/components/FormSelect"
import FilterBar from "shared/components/FilterBar"
import SearchSelector from "shared/containers/SearchSelector"
import SortBySelector from "shared/containers/SortBySelector"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import Loader from "mill/components/Loader"
import Button from "shared/components/Button"
import CursorPagination from "shared/components/CursorPagination"
import { List } from "shared/containers/List"
import { md } from "mill/utils/breakpoints"
import LearnerListItem from "mill/components/Admin/LearnerListItem"
import Checkbox from "shared/components/Checkbox"
import EmptyState from "mill/components/EmptyState"
import colors from "shared/utils/colors"

import useModal from "shared/hooks/useModal"
import useNotifications from "shared/hooks/useNotifications"
import useConfirmationModal from "shared/hooks/useConfirmationModal"
import useSelectable from "mill/hooks/useSelectable"
import useTeamOptions from "mill/hooks/useTeamOptions"
import useCursorPagination from "shared/hooks/useCursorPagination"
import useFilters from "shared/hooks/useFilters"
import { Flex } from "mill/utils/shared/core"

import FETCH_TEAM_MEMBERSHIPS from "mill/graphql/FetchTeamMemberships"
import MOVE_TEAM_MEMBERSHIPS from "mill/graphql/MoveTeamMemberships"
import RETIRE_TEAM_MEMBERSHIPS from "mill/graphql/RetireTeamMemberships"
import UPDATE_TEAM_MEMBERSHIP_ROLE from "mill/graphql/UpdateTeamMembershipRole"
import RETIRE_TEAM from "mill/graphql/RetireTeam"

export const SearchSortGrid = styled.div`
  @media ${md} {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 1.5rem;
  }
`

export const ModalContainer = styled.div`
  flex: 1;
  background: white;
  border: 3px solid ${colors.graySolitude};
  padding: 1.5rem 2rem 2rem 2rem;
  position: relative;
  min-width: 40rem;
`

const sortOptions = [
  { label: "Name (A→Z)", value: "users.name asc" },
  { label: "Name (Z→A)", value: "users.name desc" }
]

const TeamLearnersTable = ({ team, toggleAddMemberModal }) => {
  const { displayNotification } = useNotifications()
  let navigate = useNavigate()
  const { teamOptions } = useTeamOptions()
  const { getParamValue } = useFilters()
  const searchTerm = getParamValue("searchTerm") || ""
  const sortBy = getParamValue("sortBy")
  const { modalOpen, toggleModal } = useModal()
  const cursorPagination = useCursorPagination()
  const selectable = useSelectable()
  const { showConfirmationModal } = useConfirmationModal()
  const { selections, handleSelectAll, handleDeselectAll } = selectable

  const { loading, data } = useQuery(FETCH_TEAM_MEMBERSHIPS, {
    variables: {
      teamId: team.id,
      searchTerm,
      sortBy,
      ...cursorPagination
    }
  })

  const [moveTeamMemberships] = useMutation(MOVE_TEAM_MEMBERSHIPS, {
    refetchQueries: () => ["FetchTeamMemberships"],
    onCompleted: () => {
      displayNotification({
        text: `${selections.length} learners moved`,
        style: "success"
      })
      handleDeselectAll()
      toggleModal()
    }
  })

  const [retireTeamMemberships] = useMutation(RETIRE_TEAM_MEMBERSHIPS, {
    refetchQueries: () => ["FetchTeamMemberships"],
    onCompleted: () => {
      displayNotification({
        text: `${selections.length} learners removed from the team`,
        style: "success"
      })
      handleDeselectAll()
    }
  })

  const [updateTeamMembershipRole] = useMutation(UPDATE_TEAM_MEMBERSHIP_ROLE, {
    onCompleted: ({ updateTeamMembershipRole }) => {
      const {
        user: { name },
        role
      } = updateTeamMembershipRole.teamMembership
      displayNotification({
        text: `${name} has been marked as a ${role}`,
        style: "success"
      })
    }
  })

  const [retireTeam] = useMutation(RETIRE_TEAM, {
    onCompleted: ({ retireTeam }) => {
      displayNotification({
        text: `${retireTeam.team.name} retired`,
        style: "success"
      })
      navigate("/admin/learners/teams")
    }
  })

  const handleRetireTeam = () => {
    showConfirmationModal({
      title: `Retire this team from ${window.PLATFORM_NAME}?`,
      text: `Only continue if you want to retire this team from ${window.PLATFORM_NAME} forever.`,
      yesText: `Yes, retire the team`,
      yesAction: () => {
        retireTeam({
          variables: {
            teamId: team.id
          }
        })
      }
    })
  }

  const handleUpdateRole = (teamMembership, role) => {
    updateTeamMembershipRole({
      variables: {
        teamMembershipId: teamMembership.node.id,
        role
      }
    })
  }

  const teamMemberships = data?.teamMemberships?.edges || []
  const noSelections = selections.length === 0
  const allSelected =
    selections.length === teamMemberships.length && !noSelections
  const selectAllText = allSelected ? "Unselect all" : "Select all"
  const pluralisedLearners = selections.length === 1 ? "learner" : "learners"
  const selectVisibleLearners = () => {
    handleSelectAll(teamMemberships.map(tm => tm.node.id))
  }

  if (!loading && !searchTerm && teamMemberships.length === 0) {
    return (
      <EmptyState imagePath="static/empty-state/team-learners.png?auto=format,compress">
        <Heading level={2}>Add your first learner to {team.name}</Heading>
        <Text tone="dark">
          There are currently no team members for this team, add some now.
          <br />
          Alternatively, you can retire this team and send it to the nether.
        </Text>
        <Box
          display={{ tablet: "flex" }}
          justifyContent={{ tablet: "center" }}
          paddingTop="small">
          <Button
            color="secondary"
            label={"Add learners"}
            onClick={toggleAddMemberModal}
          />
          <Box paddingLeft={{ tablet: "small" }}>
            <Button
              data-qa="retire-team"
              color="secondary"
              variant="hollow"
              onClick={handleRetireTeam}
              label="Retire team"
            />
          </Box>
        </Box>
      </EmptyState>
    )
  }

  return (
    <Flex>
      <FilterBar
        toggleable={false}
        visibleComponent={
          <SearchSortGrid>
            <SearchSelector autoFocus={true} placeholder={"Find a learner"} />
            <SortBySelector options={sortOptions} />
          </SearchSortGrid>
        }></FilterBar>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingTop="medium"
        paddingBottom="medium">
        <Box display="flex">
          <Box paddingRight="medium">
            <Checkbox
              solo
              selector
              data-qa="select-all"
              checked={allSelected}
              onCheck={selectVisibleLearners}
              onUnCheck={handleDeselectAll}
            />
          </Box>
          <Text
            onClick={allSelected ? handleDeselectAll : selectVisibleLearners}>
            {selectAllText}
            {!noSelections && (
              <span>
                {" "}
                ({selections.length} {pluralisedLearners} selected)
              </span>
            )}
          </Text>
        </Box>
        <Box display={{ tablet: "flex" }} textAlign="right">
          <Box
            paddingBottom={{ mobile: "xsmall", tablet: "none" }}
            paddingRight={{ tablet: "xsmall" }}>
            <Button
              data-qa="move-team"
              disabled={noSelections}
              onClick={toggleModal}
              variant="hollow"
              size="small"
              label="Move teams"
            />
          </Box>
          <Button
            data-qa="remove-from-team"
            disabled={noSelections}
            color="error"
            size="small"
            onClick={() => {
              retireTeamMemberships({
                variables: {
                  teamMembershipIds: selections,
                  teamId: team.id
                }
              })
            }}
            label="Remove from team"
          />
        </Box>
      </Box>

      {!loading && searchTerm && teamMemberships.length === 0 && (
        <Box paddingTop="medium">
          <Text>
            Your search didn&apos;t return any results. Please try a different
            spelling or typing fewer characters!
          </Text>
        </Box>
      )}

      <Box>
        {loading ? (
          <Loader />
        ) : (
          <List data-qa="learners-table">
            {teamMemberships.map(tm => {
              const {
                node: { user }
              } = tm
              const oppositeRole =
                tm.node.role === "manager" ? "learner" : "manager"
              /* show 'Active?' ?? */
              return (
                <LearnerListItem
                  key={user.id}
                  data-qa={`team-membership-${tm.node.id}`}
                  learner={user}
                  selectable={selectable}
                  selectableId={tm.node.id}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems={{ tablet: "end" }}
                    paddingTop={{ mobile: "small", tablet: "none" }}>
                    <Box paddingRight="xsmall">
                      <Button
                        label={"View learner"}
                        color="primary"
                        variant="hollow"
                        size="xsmall"
                        data-qa={`view-learner-${user.id}`}
                        to={`/admin/learners/${user.id}/results`}
                      />
                    </Box>
                    <Button
                      label={`Make ${oppositeRole}`}
                      onClick={e => {
                        e.stopPropagation()
                        handleUpdateRole(tm, oppositeRole)
                      }}
                      size="xsmall"
                      color="primary"
                      variant="hollow"
                      data-qa="toggle-role-button"
                      style={{ minWidth: "11rem" }}
                    />
                  </Box>
                </LearnerListItem>
              )
            })}
          </List>
        )}
      </Box>

      {data && data.teamMemberships && (
        <CursorPagination {...data.teamMemberships.pageInfo} />
      )}

      <Modal
        open={modalOpen}
        closeModal={toggleModal}
        overflow={{ mobile: "visible" }}
        data-qa="move-members"
        showCloseIcon>
        <Heading level={2}>Move Team Members</Heading>
        <Text>Select a team to move {selections.length} learner(s) to:</Text>

        <Formik
          initialValues={{ newTeam: "" }}
          onSubmit={(values, actions) => {
            moveTeamMemberships({
              variables: {
                teamMembershipIds: selections,
                newTeamId: values.newTeam
              }
            })
            actions.setSubmitting(false)
            actions.resetForm()
          }}>
          {({ isSubmitting, dirty }) => {
            return (
              <Form>
                <Field
                  name="newTeam"
                  noOptionsMessage={() => "No teams found"}
                  component={Select}
                  placeholder="Select a team..."
                  type="select"
                  options={teamOptions}
                />
                <Button
                  color="secondary"
                  style={{ marginTop: "1.5rem" }}
                  label={"Move team members"}
                  disabled={!dirty || noSelections || isSubmitting}
                  fullWidth
                  type="submit"
                />
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </Flex>
  )
}

export default TeamLearnersTable
